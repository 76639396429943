import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Carousel from "./carousel"
import Footer from "./footer";

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header />

      <Carousel />

      <main className="flex flex-col flex-1 md:justify-center max-w-6xl mx-auto px-2 py-6 md:p-4 w-full text-xl">
        {children}
      </main>

      <Footer />

    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
