import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import pic1 from "../images/peopledata.svg"
import pic2 from "../images/e-commerce.svg"

export default ({ children }) => (
  <div className="carousel-outer bg-primary">
    <Carousel infiniteLoop autoPlay emulateTouch useKeyboardArrows showStatus={false} showThumbs={false}>

      <div className="md:flex md:flex-wrap items-center justify-between max-w-6xl mx-auto p-8 md:p-4 w-full">
        <div className="md:w-1/2 text-white p-1 md:p-6">
          <span className="md:text-6xl text-4xl tracking-normal leading-none">E-commerce sem Plataforma.</span>
          <br /><br />
          <span className="md:text-2xl text-xl">Headless e-commerce com integração direta com o seu ERP.</span>
          <br />
            <a
            className="font-bold no-underline text-secondary"
            href="/contact"
            >
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-10">
                Fale com um especialista
              </button>
            </a>
        </div>
        <div className="md:w-1/2">
          <img
            className="md:h-full block object-scale-down"
            alt="e-commerce"
            src={pic2}
            />
        </div>
      </div>

      <div className="md:flex md:flex-wrap items-center justify-between max-w-6xl mx-auto p-8 md:p-4 w-full">
        <div className="md:w-1/2 text-white p-1 md:p-6">
          <span className="md:text-6xl text-4xl tracking-normal leading-none">Transformamos a sua presença digital.</span>
          <br /><br />
          <span className="md:text-2xl text-xl">De marketing à inteligência artificial, podemos acelerar a geração de negócios e resultados.</span>
          <br />
            <a
            className="font-bold no-underline text-secondary"
            href="/contact"
            >
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-10">
                Fale com um especialista
              </button>
            </a>
        </div>
        <div className="md:w-1/2">
          <img
            className="md:h-full block object-scale-down"
            alt="people data"
            src={pic1}
            />
        </div>
      </div>

    </Carousel>
  </div>
)
